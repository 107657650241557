const fetchServices = async (page) => {
    const ajaxBody = new FormData();
    ajaxBody.append("action", "custom-load-services");

    try {
        // ajaxurl was set globally via wp-content/themes/lindstrom-eshop-astra-child/DO-NOT-TOUCH/functions/scripts.php
        const fetchResponse = await fetch(ajaxurl, {
            method: "POST",
            body: ajaxBody,
        });
        return await fetchResponse.text();
    } catch {
        return "";
    }
};

const initServiceList = async (blockWrapper) => {
    const serviceGridEls = blockWrapper.querySelectorAll(
        ".service-list-grid__grid"
    );
    if (serviceGridEls.length === 0) {
        return;
    }

    const serviceListResponse = await fetchServices();
    serviceGridEls.forEach((serviceGridEl) => {
        serviceGridEl.innerHTML = serviceListResponse;

        //Swap the button text if set in the block settings
        const buttonLabel = serviceGridEl.dataset.buttonLabel;
        if (buttonLabel) {
            const serviceButtons =
                serviceGridEl.querySelectorAll(".service-button");
            serviceButtons.forEach(
                (button) => (button.innerHTML = buttonLabel)
            );
        }
    });
};

export const initServiceListGrid = (blockWrapper) => {
    initServiceList(blockWrapper);
};

const setupServiceListGrid = () => {
    const blocks = document.querySelectorAll(".service-list-grid");
    blocks?.forEach(initServiceListGrid);
};

setupServiceListGrid();
