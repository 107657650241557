import multiSelect from "multiselect-dropdown";
import "multiselect-dropdown/multiselect-dropdown.css";

const initInputInteraction = (blockWrapper) => {
    /**
     * Trigger post list reloading when filter changed
     */
    const filterInputs = blockWrapper.querySelectorAll(
        ".post-list-with-filter__filter-input"
    );
    filterInputs.forEach((filterInput) => {
        filterInput.addEventListener("change", async (event) => {
            repopulatePostList(event);
        });
    });

    /**
     * Trigger post list reloading when user press Enter
     */
    const filterTextInputs = blockWrapper.querySelectorAll(
        ".post-list-with-filter__filter-input[type=text]"
    );
    filterTextInputs.forEach((filterTextInput) => {
        filterTextInput.addEventListener("keydown", (event) => {
            if (event.code === "Enter") {
                event.preventDefault();
                repopulatePostList(event);
            }
        });
    });
};

/**
 * Pagination links custom action
 */
const setCustomPaginationLinkActions = (postGridEl) => {
    const paginationLinks = postGridEl.querySelectorAll(
        ".post-list-with-filter__pagination .page-numbers"
    );
    paginationLinks.forEach((link) => {
        link.addEventListener("click", customPaginationLinkAction);
    });
};

const customPaginationLinkAction = (event) => {
    event.preventDefault();
    // the url follows the format [page url]?paged=pagenum
    // so the page number can be retrieved by getting the part after paged=
    try {
        const pageNumber = event.target.getAttribute("href").split("paged=")[1];
        repopulatePostList(event, pageNumber);
    } catch (error) {
        // in case there are some unexpected error e.g. the url is messed up
        return;
    }
};

/**
 * Re populate the post list when a filter value changed
 * @param {*} event event where user changed a filter value (e.g. checkbox / text)
 */
const repopulatePostList = async (event, pageNumber) => {
    const formWrapperEl = event.target.closest("form.post-list-with-filter");

    const postListWrapperEl = formWrapperEl.querySelector(
        ".post-list-with-filter__grid"
    );
    // remove pagination link event listener
    const paginationLinks = postListWrapperEl.querySelectorAll(
        ".post-list-with-filter__pagination .page-numbers"
    );
    paginationLinks.forEach((link) => {
        link.removeEventListener("click", customPaginationLinkAction);
    });
    // clear the post list container's content
    postListWrapperEl.innerHTML = "";

    // show the loading status
    const statusEl = formWrapperEl.querySelector(
        ".post-list-with-filter__status"
    );
    statusEl.style.display = "block";
    const statusMessageEl = statusEl.querySelector(
        ".post-list-with-filter__message"
    );
    statusMessageEl.innerHTML = formWrapperEl.querySelector(
        ".post-list-with-filter__message--loading"
    ).innerHTML;

    // only collect filter inputs in the same form
    const filters = getFilterObject(formWrapperEl);
    const filteredPostsHTML = await fetchPosts(filters, pageNumber || 1);

    const noPostMessage = formWrapperEl.querySelector(
        ".post-list-with-filter__message--no-posts"
    ).innerHTML;
    const noPostHTML = `<div class="post-list-with-filter__message">${noPostMessage}</div>`;

    // hide the loading status and show the posts
    statusEl.style.display = "none";
    postListWrapperEl.innerHTML = !filteredPostsHTML.trim()
        ? noPostHTML
        : filteredPostsHTML;

    setCustomPaginationLinkActions(postListWrapperEl);
};

/**
 * Generate the filter object based on the form input
 * @param {*} formEl the HTML Form element
 * @returns the filter object to pass to the ajax call
 */
const getFilterObject = (formEl) => {
    const formData = new FormData(formEl);
    return {
        search: formData.get("search"),
        categories: formData.getAll("categories[]"),
        tags: formData.getAll("tags[]"),
        excluded_categories: formData.getAll("excluded_categories[]"),
    };
};

/**
 * Call custom function to load the post list in html format
 * filters:
 *  - categories
 *  - tags
 *  - search query
 * page number
 */
const fetchPosts = async (filters, page) => {
    const ajaxBody = new FormData();
    ajaxBody.append("action", "custom-load-posts");
    ajaxBody.append("page", page || 1);

    if (filters) {
        Object.keys(filters).forEach((key) => {
            ajaxBody.append(key, filters[key]);
        });
    }

    try {
        // ajaxurl was set globally via wp-content/themes/lindstrom-eshop-astra-child/DO-NOT-TOUCH/functions/scripts.php
        const fetchResponse = await fetch(ajaxurl, {
            method: "POST",
            body: ajaxBody,
        });
        return await fetchResponse.text();
    } catch {
        return "";
    }
};

/**
 * Run on page first load: find all of the post list components to populate initial posts to
 */
const initPostList = async (blockWrapper) => {
    const postGridEls = blockWrapper.querySelectorAll(
        ".post-list-with-filter__grid"
    );
    if (postGridEls.length === 0) {
        return;
    }

    // populate the post in all the post list component in the page
    const filters = getFilterObject(blockWrapper);
    const postListResponse = await fetchPosts(filters, 1);

    if (!postListResponse.trim()) {
        const noPostMessage = blockWrapper.querySelector(
            ".post-list-with-filter__message--no-posts"
        ).innerHTML;
        const noPostHTML = `<div class="post-list-with-filter__message">${noPostMessage}</div>`;
        const mainSectionEl = blockWrapper.querySelector(
            ".post-list-with-filter__main"
        );
        mainSectionEl.innerHTML = noPostHTML;
        return;
    }

    postGridEls.forEach((postGridEl) => {
        postGridEl.innerHTML = postListResponse;
        setCustomPaginationLinkActions(postGridEl);
    });
};

const initMultiSelect = (blockWrapper) => {
    const selectEls = blockWrapper.querySelectorAll(
        "select.post-list-with-filter__filter-input"
    );
    if (selectEls) {
        selectEls.forEach((el) => {
            // "transform" the select into multiple dropdown control
            const dropdown = new multiSelect(el, {
                className: ".post-list-with-filter__filter-input",
                maxVisibleOptions: 5,
            });

            const selectDisplay = dropdown.element.querySelector(
                ".multi-select-display"
            );

            if (el.selectedOptions?.length > 0) {
                // display the pre-selected options if any
                selectDisplay.innerHTML = Array.from(el.selectedOptions)
                    .map((option) => option.label)
                    .toString();
            } else {
                // set the placeholder text when no item is selected
                const placeholderText = el.getAttribute("placeholder");
                selectDisplay.innerHTML = placeholderText;
            }

            // as the dropdown change the original select element's value programmatically,
            // the change could not be detected via addEventListener
            // Credit: Nikita Hlopov
            // Link: https://nikitahl.com/listen-for-class-change-in-javascript
            const observer = new MutationObserver((mutationList) => {
                mutationList.forEach((mutation) => {
                    // the select value changed when user close the option list.
                    // which means, the indicator class `multi-select-open` is removed from the wrapper element's class list
                    // do nothing if the change observed is not in the mentioned criteria
                    if (
                        mutation.type !== "attributes" ||
                        mutation.attributeName !== "class" ||
                        dropdown.element.classList.contains("multi-select-open")
                    ) {
                        return;
                    }

                    // show the placeholder text if all items are unchecked
                    if (el.selectedOptions.length === 0) {
                        const placeholderText = el.getAttribute("placeholder");
                        selectDisplay.innerHTML = placeholderText;
                    }

                    // let the form know that the select's value has changed
                    var changeEvent = new Event("change");
                    el.dispatchEvent(changeEvent);
                });
            });
            observer.observe(dropdown.element, { attributes: true });
        });
    }
};

export const initPostListWithFilter = (blockWrapper) => {
    initInputInteraction(blockWrapper);
    initMultiSelect(blockWrapper);
    initPostList(blockWrapper);
};

const setupPostListWithFilter = () => {
    const blocks = document.querySelectorAll(".post-list-with-filter");
    blocks?.forEach(initPostListWithFilter);
};

setupPostListWithFilter();
