import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

export const initGlobalSiteHero = (blockWrapper) => {

    console.error('init hero slide for', blockWrapper)
    if (!blockWrapper || !blockWrapper.querySelector(".splide")) {
        return;
    }

    const slider = new Splide(`#${blockWrapper.id} .splide`, {
        arrows: true,
        type: "fade",
        drag: true,
        speed: 500,
        pagination: true,
        autoplay: true,
        type: "loop",
        start: 0,
        lazyLoad: "nearby",
        preloadPages: 1
    }).mount();
};

const setupGlobalSiteHero = () => {
    const blocks = document.querySelectorAll(".global-site-hero");
    blocks?.forEach(initGlobalSiteHero);
};

setupGlobalSiteHero();
